<template>
  <div class="d-flex align-center barcode-container mx-2">
    <v-btn
      class="primary--text ml-0 ml-sm-3 barcode-btn"
      :class="{ 'no-border': $vuetify.breakpoint.smAndDown }"
      :loading="loading"
      :large="!$vuetify.breakpoint.xs"
      icon
      x-small
      @click="scan()"
    >
      <v-icon>
        $barcodeScan
      </v-icon>
    </v-btn>
  </div>
</template>
<style lang="scss" scoped>
.icon-barcode-scan:before {
  color: $secondary;
  font-size: 24px;
}
</style>
<script>
export default {
  name: "Barcode",
  props: ["formats"],
  data() {
    return {
      loading: false
    };
  },
  computed: {},
  methods: {
    scan() {
      if (window.cordova && window.cordova.plugins.barcodeScanner) {
        try {
          this.loading = true;
          // eslint-disable-next-line no-undef
          cordova.plugins.barcodeScanner.scan(
            result => {
              if (result) {
                this.loading = false;
                this.$emit("input", {
                  type: "Barcode",
                  name: result.text
                });
              }
            },
            error => {
              this.loading = false;
              console.error("Scanning failed", error);
            },
            {
              showFlipCameraButton: true,
              showTorchButton: true,
              resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
              formats:
                this.formats ||
                "EAN_8,EAN_13,CODE_128,CODE_39,CODE_93,CODABAR,UPC_A,UPC_E"
            }
          );
        } catch (err) {
          console.log(err);
        }
      }
    }
  }
};
</script>
<style lang="scss">
.barcode-container {
  .barcode-btn {
    width: 24px !important;
    height: 24px !important;
  }
}
</style>
